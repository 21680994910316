<template lang="pug">
.phone-page
  .line
    |+86
    .input-box.phone-input
      input.input(
        maxlength="11",
        placeholder="请输入手机号",
        v-model="state.phone",
        @input="onInput($event, 'phone')",
        @focus="onInputFocus",
        @blur="onInputBlur")
    .clear-btn(v-show="state.isFocus && state.phone", @click="onClearClick")
  .line
    .input-box.code-input
      input.input(
        ref="codeInput"
        maxlength="6",
        v-model="state.code"
        placeholder="请输入验证码",
        size="1",
        @input="onInput($event, 'code')")
    .other-section
      .code-btn(v-if="[1, 3].includes(codeState)",
        @click="getCode") {{codeState === 1 ? '获取验证码' : '重新获取'}}
      .time-sec(v-if="codeState === 2") {{time}}s
  .confirm-button(@click="confirm", :class="{ disabled: isButtonDisabled }") 确认
</template>

<script setup>
import {
  ref, reactive, onUnmounted, computed,
} from 'vue'
import service from '@/services/parents'
import { useRoute, useRouter } from 'vue-router'

const time = ref(60)
const codeState = ref(0) // 0 不展示按键，1 “获取验证码”，2 倒计时，3 “重新获取”
const state = reactive({
  phone: '',
  code: '',
  isFocus: false,
})

const isButtonDisabled = computed(() => !(state.phone && state.code.length === 6 && codeState.value > 1))
let timeId = null
const countTime = () => {
  if (time.value === -1) {
    codeState.value = state.phone.length === 11 ? 3 : 0
    return
  }
  timeId = setTimeout(() => {
    time.value -= 1
    countTime()
  }, 1000)
}

const codeInput = ref()
const onInput = (e, type) => {
  const numVal = e.target.value.replace(/\D/g, '')
  if (type === 'code') {
    state.code = numVal.slice(0, 6)
    return
  }
  state.phone = numVal.slice(0, 11)
  if (codeState.value === 0 && state.phone.length === 11) {
    codeState.value = 1
  }
  if (codeState.value !== 2 && state.phone.length !== 11) {
    codeState.value = 0
  }
}
const getCode = () => {
  service.getPhoneCode(state.phone)
    .then(() => {
      time.value = 60
      codeState.value = 2
      countTime()
      codeInput.value.focus()
    })
    .catch((res) => {
      if (res.code === 103) {
        window.ydk.toast({ msg: '请输入正确的手机号' })
        return
      }
      window.ydk.toast({ msg: '获取验证码失败，请稍后再试' })
    })
}

const route = useRoute()
const router = useRouter()
const updatePhone = () => {
  const { orderId } = route.query
  service.updatePhone({
    orderId,
    userPhone: state.phone,
  })
    .then(() => {
      window.ydk.closeWebview()
    })
}
const confirm = () => {
  if (isButtonDisabled.value) {
    return
  }
  service.verifyPhoneCode(state.phone, state.code)
    .then((res) => {
      updatePhone()
    })
    .catch(() => {
      window.ydk.toast({ msg: '验证码错误' })
    })
}

let inputTimeId = null
const onClearClick = () => {
  state.phone = ''
}

const onInputFocus = () => {
  clearTimeout(inputTimeId)
  state.isFocus = true
}
const onInputBlur = () => {
  inputTimeId = setTimeout(() => {
    state.isFocus = false
  }, 300)
}

onUnmounted(() => {
  clearTimeout(timeId)
})
</script>

<style lang="stylus" scoped>
placeholder()
  &::-webkit-input-placeholder
    {block}
  &:-moz-placeholder
    {block}
  &::-moz-placeholder
    {block}
  &:-ms-input-placeholder
    {block}
.phone-page
  width 3.9rem
  margin 0 auto
  padding 0.08rem 0.4rem
  box-sizing border-box
.line
  color #BFBFBF
  padding 0.32rem 0 0.16rem
  border-bottom 1px solid #f5f5f5
  display flex
  align-items center
.line, .input
  font-size 0.18rem
  line-height 0.28rem
  font-weight 500
.input-box
  flex 1
.input
  border none
  box-shadow none
  outline none
  padding 0
  margin 0
  -webkit-appearance none
  border-radius 0
  background transparent
  color #3a3a3a
  caret-color #FF333A
  width 100%
  min-width 0
  +placeholder()
    color #BFBFBF
    font-size 0.16rem
    line-height 0.24rem
    color #bfbfbf
.phone-input
  margin-left 0.2rem
.other-section
  width 1.2rem
  text-align right
.code-btn
  font-size 0.16rem
  font-weight 500
  color #4492FF
.confirm-button
  margin-top 0.44rem
.clear-btn
  width 0.2rem
  height 0.2rem
  background url('~@/assets/parents/ic_close.png') 0 0 / 100% 100% no-repeat
</style>
