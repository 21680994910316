import $http from '../index';

function getCampInfo(params) {
  const path = '/v1/advertising/getHomePageInfo';
  return $http(path, 'get', params)
}

function getVersionList(params) {
  const path = '/v1/order/courseVersionGradeList'
  return $http(path, 'get', params)
}

function getChildrenList() {
  const path = `${process.env.VUE_APP_BASE_SERVER_URL}/app/ybind/childrenList`
  return $http(path, 'get', { childProduct: 'HWPanda' })
}

function claimCourse(params) {
  const path = '/v1/redeem/use'
  return $http(path, 'post', params)
}

function getOrders() {
  const path = '/v1/order/getOrdersByParentId'
  return $http(path, 'get', {})
}

function getOrderDetail(params) {
  const path = '/v1/order/orderInfoByUserIdAndGoodsId'
  return $http(path, 'get', params)
}

function getCourseState(params) {
  const path = '/v1/order/detail'
  return $http(path, 'get', params)
}

function getPhoneCode(phone) {
  const path = '/v1/phone/fetch/code'
  return $http(path, 'get', { phone })
}

function verifyPhoneCode(phone, verifyCode) {
  const path = '/v1/phone/verify/code'
  return $http(path, 'post', { phone, verifyCode })
}

function updatePhone(params) {
  const path = '/v1/order/updateUserPhone'
  return $http(path, 'post', params)
}

/**
 *  获取预报名信息
 * @param {number} goodsId 商品id
 */
function getSignUpInfo(goodsId) {
  const path = '/v1/order/app/signUpInfo'
  return $http(path, 'get', { goodsId })
}

/**
 *  获取广告（课程）关联的年级和版本列表
 * @param {number} goodsId 商品id
 */
function getMinProgramCourseVersionGradeList(goodsId) {
  const path = '/v1/order/getMinProgramCourseVersionGradeList'
  return $http(path, 'get', { goodsId })
}

/**
 *  app端家长账号预报名
 * @param {object} data
 * @param {number} data.goodsId 商品id
 * @param {string} data.grade 年级
 * @param {string} data.version 教材版本
 * @param {string} data.phone  年级
 */
function signUpFreeOrder(data) {
  const path = '/v1/order/app/signUp'
  return $http(path, 'post', data)
}

export default {
  signUpFreeOrder,
  getCampInfo,
  getVersionList,
  getChildrenList,
  claimCourse,
  getOrders,
  getOrderDetail,
  updatePhone,
  getPhoneCode,
  verifyPhoneCode,
  getCourseState,
  getSignUpInfo,
  getMinProgramCourseVersionGradeList,
};
